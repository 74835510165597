import moment from "moment";

const calcPaginationParams = (currentPage, perPage) => {

    return {
        skip: (currentPage - 1) * perPage,
        limit: perPage,
        pagination: true,
    }
}

const FILTERS = {
    quicksearch: (key, value) => `${key} contains '${value}'`,
    search: (key, value) => `${key} like '%${value}%'`,
    select: (key, value, combineWith) => {
        if (combineWith) {
            //for this to work, the statement has to be in the last position of the query
            //if there are two statements of this kind, it won't work - TD on BE
            //return `${key} in [${value}, ${combineWith}]`;
            return `${key} contains ${value}`;
        } else {
            return `${key} eq '${value}'`;
        }
    } ,
    autocomplete: (key, value) => `${key} eq '${value}'`,
    contains: (key, value) => `${key} contains '${value}'`,
    daterange: (key, value) => {
        const startQuery = `${key} ge '${value.start}'`;

        const end = moment(value.end).add(1, 'day').format('YYYY-MM-DD');
        const endQuery = `${key} lt '${end}'`;

        if (value?.start && value?.end) {
            return `${startQuery} and ${endQuery}`;
        }
        if (value?.start) {
            return startQuery;
        }
        if (value?.end) {
            return endQuery;
        }
    },
    selectablequeryfilter: (key, value) => value
};

const calcFiltersParams = (filters) => {
    let beneficiaryPncOrUniqueCodeCustomValue;
    const queries = filters
        .filter(filter => {
            if (filter.type === 'daterange') {
                return filter.value.start || filter.value.end;
            }
            if (filter?.type === 'search' && filter?.name === 'form.beneficiary_users.pnc_or_unique_code') {
                beneficiaryPncOrUniqueCodeCustomValue = filter.value;
                return;
            }
            return filter.value && !filter.sortByKey;
        })
        .map(filter => {
            if (filter.searchByText && filter.type ==='contains') {
                const filterValue = filter.options.find((filterItem) => filterItem.value === filter.value)?.text;
                return FILTERS[filter.type](filter.key, filterValue)
            }

            if (filter?.combineWith) {
                return FILTERS[filter.type](filter.key, filter.value, filter.combineWith);
            }

            return FILTERS[filter.type](filter.key, filter.value)
        });
    let filterParamsResult = {
        query: queries.join(' and '),
    };
    if (beneficiaryPncOrUniqueCodeCustomValue) {
        filterParamsResult['orLikeQuery'] = `form.beneficiary_users.personal_numeric_code like '%${beneficiaryPncOrUniqueCodeCustomValue}%' or form.beneficiary_users.unique_registration_code like '%${beneficiaryPncOrUniqueCodeCustomValue}%'`
    }  

    return filterParamsResult;
}

const calcSortParams =  (filters) => {
    const sort = {}
    filters.forEach((filter) => {
        if (filter.sortByKey) {
            const selectedSortKey = filter.options.find(option => option.value === filter.value)?.sortkey;
            if (selectedSortKey) sort.sortKey = selectedSortKey;
        }
    })

    return sort;
}

const calcParams = (params) => {
    const { currentPage, perPage, filters } = params;

    const paginationParams = calcPaginationParams(currentPage, perPage);
    const filtersParams = calcFiltersParams(filters);
    const sortingParams = calcSortParams(filters);

    return {
        ...paginationParams,
        ...filtersParams,
        ...sortingParams,
    };
}

export default calcParams;
