import i18n from '@/shared/plugins/vue-i18n';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';

const DEFAULT_ERROR_MESSAGE = 'DEFAULT_ERROR_MESSAGE';

function selectErrorMessage(error) {
    let errorMessage;
    if(Array.isArray(error?.response?.data?.message)) {
        errorMessage = error.response.data.message[0];
    } else {
        errorMessage = error?.response?.data?.message;
    }

    errorMessage = errorMessage || DEFAULT_ERROR_MESSAGE;

    return i18n.t(`ERRORS.${errorMessage.toUpperCase()}`);
}

function selectErrorCode(error) {
    return error?.response?.status || 500;
}

export default class Errors {
    static handle(error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(selectErrorMessage(error));
            console.error(error);
        }

        if (selectErrorCode(error) === 403) {
            return;
        }

        const errorCodes = [400, 401, 409, 412, 504];
        const currentErrorCode = selectErrorCode(error);

        if (errorCodes.includes(currentErrorCode)) {
            Message.error(selectErrorMessage(error));
            return;
        }

        //network error
        if (!error.response) {
            Message.error(selectErrorMessage(error));
            return;
        }

        routerAsync().push('/500');
    }

    static errorCode(error) {
        return selectErrorCode(error);
    }

    static selectMessage(error) {
        return selectErrorMessage(error);
    }

    static showMessage(error) {
        Message.error(selectErrorMessage(error));
    }
}
