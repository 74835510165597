import { SET_UPLOAD_TOKEN, FIND_SUCCESS } from '@/modules/applications/store/applications-form-store';
import ApiService from '@/shared/services/api.service';

// action types
export const DO_SEND_CIRCULAR = 'doSendCircular';
export const GET_CIRCULAR_TOKEN = 'getCircularToken';
// mutation types
export const SET_UPLOAD_ACCESS_TOKEN = 'setUploadAccessToken';

const state = {
    uploadToken: null,
};

const getters = {
    uploadAccessToken: (state) => state.uploadToken,

};

const actions = {
    [DO_SEND_CIRCULAR](context, data) {
        context.commit('shared/activateLoading', 'notifications/sendCircular', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post('notifications/circular', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ error }) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'notifications/sendCircular', { root: true });
                })
        });
    },
    [GET_CIRCULAR_TOKEN](context) {
        context.commit('shared/activateLoading', 'notifications/getCircularToken', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get('notifications/circular/token')
                .then(({ data }) => {
                    const uploadAccessToken = data;
                    context.commit(SET_UPLOAD_ACCESS_TOKEN, uploadAccessToken);

                    resolve({ data, uploadAccessToken });

                })
                .catch(({ error }) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'notifications/getCircularToken', { root: true });
                })
        });
    },
};

const mutations = {
    [SET_UPLOAD_ACCESS_TOKEN](state, uploadAccessToken) {
        state.uploadToken = uploadAccessToken;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
