<template>
    <div>
        <div class="st-files-container">
            <template v-if="documents.length">
                <div
                    v-for="(doc, index) in documents"
                    :key="index"
                    class="st-file-item"
                >
                    <div class="text-truncate">{{ doc.file.name }}</div>
                    <div class="st-file-item-actions">
                        <i
                            v-if="!viewMode"
                            class="st-remove-file fas fa-trash mr-4"
                            @click="removeFile(index)"
                        ></i>
                        <i
                            v-if="!doc.file.isHiddenForView"
                            class="st-view-file fas fa-eye"
                            @click="viewFile(doc)"
                        ></i>
                    </div>
                </div>
            </template>
            <div class="st-file-item" v-if="viewMode && !documents.length">
                <div class="text-truncate"><i>{{ $t("COMPLAIN.FORM.EMPTY_DOCUMENT_LABEL") }}</i></div>
            </div>
        </div>
        <div v-if="!viewMode">
            <b-form-file
                v-show="false"
                @change="uploadFile"
                :id="defaultHtmlId"
                :accept="acceptedFileTypesComputed"
                ref="upload_documents"
            ></b-form-file>
            <st-button
                v-if="uploadedFilesLimit"
                variant="primary"
                customClass="st-btn-upload"
                class="px-6 mt-4"
                :callback="pickDocuments"
                :disabled="disabled"
            >
                {{textButton}}
            </st-button>
            <div
                class="fv-plugins-message-container"
                v-if="wasValidated && !isValid"
            >
                <div class="fv-help-block">
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </div>
            </div>
            <slot name="buttons"></slot>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Notify from "@/shared/utils/notify";

// TODO: Move to settings
const DEFAULT_HTML_ID = "file-default";
const DEFAULT_MAX_APPLICATION_ATTACHMENTS = 10;
const DEFAULT_MAX_FILE_SIZE = 50; // MB
const DEFAULT_ACCEPTED_FILE_TYPES = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/zip",
];

export default {
    name: "StFile",
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        viewMode: {
            type: Boolean,
            default: false,
        },
        acceptedFileTypes: {
            type: Array,
            default: () => DEFAULT_ACCEPTED_FILE_TYPES,
        },
        maxFileSize: {
            type: Number,
            default: DEFAULT_MAX_FILE_SIZE,
        },
        maxFiles: {
            type: Number,
            default: DEFAULT_MAX_APPLICATION_ATTACHMENTS,
        },
        wasValidated: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        token: {
            type: String,
            require: false,
        },
        htmlId: {
            type: String,
            require: false,
        },
    },
    data() {
        return {
            documents: [],
            errorMessage: "",
        };
    },
    watch: {
        value() {
            this.initDocuments();
        },
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            fileToken: "applications/form/fileToken",
        }),
        uploadedFilesLimit() {
            if (this.maxFiles === 0) {
                return false;
            }
            return this.documents.length <= this.maxFiles;
        },
        acceptedFileTypesComputed() {
            if (!this.acceptedFileTypes.length)
                return DEFAULT_ACCEPTED_FILE_TYPES.join(",");
            return this.acceptedFileTypes.join(",");
        },
        textButton() {
            let text = this.$t("COMPLAIN.FORM.UPLOAD_DOCUMENT_BUTTON");
            if (this.maxFileSize) {
                text = this.$t("GENERAL.BUTTON.UPLOAD_MAX_FILE_SIZE", [this.maxFileSize]);
            }

            return text;
        },
        defaultHtmlId() {
            return this.htmlId ?? DEFAULT_HTML_ID;
        }
    },
    methods: {
        pickDocuments() {
            this.$refs.upload_documents.$el.childNodes[0].click();
        },
        uploadFile(ev) {
            const uploaded = ev.target.files[0];

            if (!this.validate(uploaded)) {
                this.clearFile(ev);
                Notify("error", this.errorMessage);
                return false;
            }
            const url = URL.createObjectURL(uploaded);
            const fileName = this.getUniqueFileName(uploaded.name);

            const newFile = {
                url,
                file: new File([uploaded], fileName, { type: uploaded.type }),
            };

            this.documents = [...this.documents, newFile];
            this.clearFile(ev);
            this.emitChangeDocument();
        },
        /**
         * If the file name exists then increment file name by one
         * eg. filename (1).png
         */
        getUniqueFileName(fileName, index = 0) {
            let checkName = fileName;
            let ext = "";
            if (index) {
                if (checkName.indexOf(".") > -1) {
                    let tokens = checkName.split(".");
                    ext = "." + tokens.pop();
                    checkName = tokens.join(".");
                }

                checkName = `${checkName} (${index})${ext}`;
            }

            const nameExists =
                this.documents.filter((el) => el.name === checkName).length > 0;
            return nameExists
                ? this.getUniqueFileName(fileName, index + 1)
                : checkName;
        },
        clearFile(ev) {
            ev.target.value = null;
        },
        validate(file) {
            if (!this.validateFilesLength()) return false;
            if (!this.validateFileSize(file)) return false;
            if (!this.validateFileType(file)) return false;
            return true;
        },
        validateFilesLength() {
            const totalFiles = this.documents.length + 1;
            if (totalFiles <= this.maxFiles) return true;
            this.errorMessage = this.$t("VALIDATION.MAX_UPLOADED_FILES", {
                max: this.maxFiles,
            });
            return false;
        },
        validateFileSize(file) {
            const fileSize = file.size / 1024 / 1024; // MB
            if (fileSize <= this.maxFileSize) return true;
            this.errorMessage = this.$t("VALIDATION.UPLOADED_FILE_SIZE");
            return false;
        },
        validateFileType(file) {
            let fileType = file.type;
            if (!fileType) {
                fileType = file.name.slice(file.name.lastIndexOf('.') + 1);
            }

            if (this.acceptedFileTypes.includes(fileType)) return true;
            this.errorMessage = this.$t("VALIDATION.UPLOADED_FILE_TYPE");
            return false;
        },
        removeFile(index) {
            this.$delete(this.documents, index);
            this.emitChangeDocument();
        },
        viewFile(doc) {
            const token = this.token || this.fileToken;
            const file_id = doc.file.file_id;
            const url = file_id
                ? `${window.VUE_APP_API_URL}/files/${file_id}?token=${token}`
                : `${doc.url}`;
            window.open(url, "_blank");
        },
        emitChangeDocument() {
            const mappedDocuments = this.documents.map((doc) => doc.file);
            this.$emit("change", mappedDocuments);
        },
        initDocuments() {
            this.documents =
                this.value?.map((file) => {
                    if (file instanceof File) {
                        const url = URL.createObjectURL(file);
                        return {
                            url,
                            file,
                        };
                    } else {
                        return {
                            file: {
                                ...file,
                                name: file.filename,
                            },
                        };
                    }
                }) ?? [];
        },
    },
    created() {
        this.initDocuments();
    },
};
</script>
<style lang=""></style>
