import { storeAsync } from '@/app-module';

const Layout = () => import('@/modules/layout/components/layout');
const getUsefulLinksModule = () => import('@/modules/useful-links/useful-links-store');
const UsefulLinksPage = () => import('@/modules/useful-links/components/useful-links.vue');


const importUsefulLinksStore = () =>
    getUsefulLinksModule().then(async (storeModule) => {
        if (!storeAsync().hasModule('usefulLinks')) await storeAsync().registerModule('usefulLinks', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        exact: true,
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'useful-links',
                path: '/useful-links',
                component: UsefulLinksPage,
                exact: true,
                meta: { auth: true },
                beforeEnter: (to, from, next) => {
                    importUsefulLinksStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
