import ApiService from '@/shared/services/api.service';
const LS_ATLAS_LAYERS_KEY = 'st_atlas_layers';
const LS_ATLAS_SIDEBAR = 'st_atlas_sidebar';
const LS_ATLAS_CONTAINERS_KEY = 'st_atlas_containers';
const LS_ATLAS_PARCEL_KEY = 'st_atlas_parcel';
import KTCookie from '@/assets/js/components/cookie.js';

export class AtlasService {

    static getLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/layers/me', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getPublicSystemLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/public-layers/', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getSystemLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/layers/me', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getPublicLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/public-layers/', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getArcgisApps(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/arcgis-apps/me', params)
                .then((response) => resolve(response.data?.data))
                .catch((error) => reject(error));
        });
    }

    static getSimpleLayers(params = {}) {
        return new Promise((resolve, reject) => {
            ApiService.query( '/layers-simple/me', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getSimpleLayer(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/layers-simple/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static createLayer(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static createLayerById(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/arcgis-import-by-id', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static publishLayer(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/publish-layer', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static compareLayers(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/publish-layer/compare-sublayers', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static updateLayer(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layer-creation-requests/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

     static addLayerIntoContainer(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/layer-creation-requests/component', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static updateLayerIntoContainer(id, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layer-creation-requests/component/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateLayerFromContainer(id, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layers-simple/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateLayerContainer(id, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layers/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static addArcgisApp(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("/arcgis-apps/?echo=true", { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateArcgisApp(id, payload) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/arcgis-apps/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static deleteLayer(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/layers/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static deleteSimpleLayer(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/layers-simple/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static deleteArcgisApp(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/arcgis-apps/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static upload(token, files) {
        return new Promise((resolve, reject) => {
           ApiService.post('/files?echo=true', files, {
               'content-type': 'multipart/form-data',
               'x-file-access-token': token,
           })
               .then((response) => resolve(response.data))
               .catch((error) => reject(error));
        });
    }

    static generateToken() {
        return new Promise((resolve, reject) => {
            ApiService.post( '/generate-token', { ...data } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }

    static getLayerCreationStatus(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/layer-creation-requests/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getGisToken(isStaff) {
        const path = isStaff ? '/gis-tokens/get-token' : '/public-gis-tokens/guest-token';
        return new Promise((resolve, reject) => {
            ApiService.get(path)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static addArea(payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('/interest-areas/?echo=true', { ...payload } )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        })
    }

    static deleteArea(area) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/interest-areas/${area.id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateArea( data) {
        const { payload, id } = data;
        return new Promise((resolve, reject) => {
            ApiService.patch(`/interest-areas/${id}`, { ...payload })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getAreas() {
         return new Promise((resolve, reject) => {
            ApiService.get('/interest-areas/')
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getUrbanismRegulations(key) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/urbanism-regulations?query=zone_key like ${key}`)
                .then((response) => resolve(response.data.data))
                .catch((error) => reject(error));
        });
    }

    static getPublicUrbanismRegulations(key) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/urbanism-regulations/public?zone_key=${key}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateSublayer(data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/${data.endpointByLayerType}/${data.layerId}/sublayers/${data.gis_id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getPersistedLayers() {
        const layersString = window.localStorage.getItem(LS_ATLAS_LAYERS_KEY);
        return layersString ? JSON.parse(layersString) : {};
    }

    static persistLayers(layers) {
        const layersString = JSON.stringify(layers);
        window.localStorage.setItem(LS_ATLAS_LAYERS_KEY, layersString);
    }

    static getPersistedSidebarState() {
        const state =  window.localStorage.getItem(LS_ATLAS_SIDEBAR);
        return state === 'true';
    }

    static persistSidebarState(state) {
        window.localStorage.setItem(LS_ATLAS_SIDEBAR, state);
    }

    static getPersistedContainers() {
        const openContainers = window.localStorage.getItem(LS_ATLAS_CONTAINERS_KEY);
        return openContainers ? JSON.parse(openContainers) : [];
    }

    static persistedContainersKeyDefined() {
        return Object.keys(window.localStorage).find((key) => key === LS_ATLAS_CONTAINERS_KEY)
    }

    static persistContainers(layers) {
        const openContainers = JSON.stringify(layers);
        window.localStorage.setItem(LS_ATLAS_CONTAINERS_KEY, openContainers);
    }

    static getPersistedParcel() {
        const parcelString = window.localStorage.getItem(LS_ATLAS_PARCEL_KEY);
        return parcelString ? JSON.parse(parcelString) : {};
    }
    static destroyPersistedParcel() {
        window.localStorage.removeItem(LS_ATLAS_PARCEL_KEY);
    }

    static reSyncSimpleLayers(id) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/layers-simple/${id}/arcgis-resync` )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static reSyncLayers(id) {
        return new Promise((resolve, reject) => {
            ApiService.post( `/layers/${id}/arcgis-resync` )
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static getLayerPopupInfo(id, gisToken) {
        return new Promise((resolve, reject) => {
            let reqUrl = `/layers/${id}/service-item-data`;
            if(gisToken) {
                reqUrl += `?gis_token=${gisToken}`
            }
            ApiService.get(reqUrl)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static getSimpleLayerPopupInfo(id, gisToken) {
        return new Promise((resolve, reject) => {
            let reqUrl = `/layers-simple/${id}/service-item-data`;
            if(gisToken) {
                reqUrl += `?gis_token=${gisToken}`
            }
            ApiService.get(reqUrl)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static getAsideToggleState() {
         return KTCookie.getCookie('kt_aside_toggle_state')
    }
    static setAsideToggleState(state) {
         KTCookie.setCookie('kt_aside_toggle_state', state);
    }

    static getUrbanismRegulationZone(key) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/urbanism-regulation-zones?query=key like ${key}`)
                .then((response) => resolve(response.data.data))
                .catch((error) => reject(error));
        });
    }

    static getPublicUrbanismRegulationZone(key) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/urbanism-regulation-zones-public?query=key like ${key}`)
                .then((response) => resolve(response.data.data))
                .catch((error) => reject(error));
        });
    }

    static extractIntersectionDataToFile(data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/layers/extract-data`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static getLayerComments(parentId, extent) {
        let reqUrl = "/layer-comments/?";

        if (extent) {
            const extentReq = `${extent.xmin},${extent.ymin},${extent.xmax},${extent.ymax}`
            reqUrl = `${reqUrl}extent=${extentReq}&`
        }

        reqUrl = parentId ?
            `${reqUrl}query=parent_id eq ${parentId}&limit=100` :
            `${reqUrl}limit=100`;
        return new Promise((resolve, reject) => {
            ApiService.get(`${reqUrl}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static addLayerComment(data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/layer-comments/?echo=true`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static patchLayerComment(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/layer-comments/${id}?echo=true`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static deleteLayerComments(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/layer-comments/${id}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}
