import Vue from 'vue';
import { fromEvent } from 'rxjs';
import { of, debounceTime } from 'rxjs/operators';

Vue.directive('columns-resizable', {
    inserted(el, binding) {
        const nodeName = el.firstElementChild?.nodeName;
        if (!['TABLE', 'THEAD'].includes(nodeName)) return;

        const table = nodeName === 'TABLE' ? el : el.parentElement;
        const thead = table.querySelector('thead');
        const ths = thead.querySelectorAll('th');
        const localStorageKey = binding.value || null;

        if (!localStorageKey) return;

        const resizeContainer = document.createElement('div');
        table.style.position = 'relative';
        resizeContainer.style.position = 'absolute';
        resizeContainer.style.width = `${table.clientWidth}px`;
        resizeContainer.className = "vue-columns-resizable";
        table.parentElement.insertBefore(resizeContainer, table);

        let moving = false;
        let movingIndex = 0;

        const loadWidthsFromLocalStorage = () => {
            const savedWidths = JSON.parse(localStorage.getItem(localStorageKey));
            if (savedWidths && savedWidths.length === ths.length) {
                ths.forEach((th, index) => {
                    th.style.width = `${savedWidths[index]}px`;
                });
            }
        };

        const saveWidthsToLocalStorage = () => {
            if (!localStorageKey) return;
            const widths = Array.from(ths).map(th => th.offsetWidth);
            localStorage.setItem(localStorageKey, JSON.stringify(widths));
        };

        loadWidthsFromLocalStorage();

        const createBars = () => {
            resizeContainer.innerHTML = '';
            ths.forEach((th, index) => {
                th.style.width = `${th.offsetWidth}px`;

                if (index + 1 >= ths.length) return;

                const nextTh = ths[index + 1];
                const bar = document.createElement('div');

                bar.style.position = 'absolute';
                bar.style.left = `${nextTh.offsetLeft - 4}px`;
                bar.style.top = '0';
                bar.style.height = `${table.offsetHeight}px`;
                bar.style.width = '8px';
                bar.style.cursor = 'col-resize';
                bar.style.zIndex = 1;
                bar.className = 'columns-resize-bar';

                bar.addEventListener('mouseenter', () => {
                    bar.style.backgroundColor = '#F3F6F9';
                });
                bar.addEventListener('mouseleave', () => {
                    bar.style.backgroundColor = '';
                });

                bar.addEventListener('mousedown', () => {
                    moving = true;
                    movingIndex = index;
                    document.body.style.cursor = 'col-resize';
                    document.body.style.userSelect = 'none';
                });

                resizeContainer.appendChild(bar);
            });
        };

        createBars();

        const bars = () => resizeContainer.querySelectorAll('.columns-resize-bar');

        document.addEventListener('mouseup', () => {
            if (!moving) return;
            moving = false;
            document.body.style.cursor = '';
            document.body.style.userSelect = '';

            bars().forEach((bar, index) => {
                const th = ths[index];
                const nextTh = ths[index + 1];
                th.style.width = `${th.offsetWidth}px`;
                bar.style.left = `${nextTh.offsetLeft - 4}px`;
            });

            saveWidthsToLocalStorage();

            updateBarsPositions();
        });

        const handleResize = (e) => {
            if (moving) {
                const th = ths[movingIndex];
                const nextTh = ths[movingIndex + 1];
                const bar = bars()[movingIndex];

                if (movingIndex === ths.length - 2) {
                    const newThWidth = parseInt(th.style.width) + e.movementX;
                    const newNextThWidth = parseInt(nextTh.style.width) - e.movementX;

                    if (newNextThWidth >= 80) {
                        nextTh.style.width = `${newNextThWidth}px`;
                    }

                    th.style.width = `${newThWidth}px`;
                } else {
                    const newThWidth = parseInt(th.style.width) + e.movementX;
                    const newNextThWidth = parseInt(nextTh.style.width) - e.movementX;

                    th.style.width = `${newThWidth}px`;
                    nextTh.style.width = `${newNextThWidth}px`;
                }

                bar.style.left = `${nextTh.offsetLeft - 4 + e.movementX}px`;
            }
        };

        resizeContainer.addEventListener('mousemove', handleResize);
        table.addEventListener('mousemove', handleResize);

        const recalculateBars = () => {
            resizeContainer.style.width = `${table.clientWidth}px`;
            createBars();

            const lastTh = ths[ths.length - 1];
            if (lastTh.offsetWidth < 80) {
                lastTh.style.width = '80px';

                const previousTh = ths[ths.length - 2];
                const previousWidth = previousTh.offsetWidth;
                previousTh.style.width = `${previousWidth + (lastTh.offsetWidth - lastTh.offsetWidth)}px`;
            }

            saveWidthsToLocalStorage();
        };

        const updateBarsPositions = () => {
            const scrollLeft = table.scrollLeft;
            bars().forEach((bar, index) => {
                const nextTh = ths[index + 1];
                bar.style.left = `${nextTh.offsetLeft - 4 - scrollLeft}px`;
                bar.style.height = `${table.offsetHeight}px`;
            });
        };

        const resizeScroll$ = fromEvent(table, 'scroll').pipe(debounceTime(100));
        const resizeWindow$ = fromEvent(window, 'resize').pipe(debounceTime(100));
        const fullscreenChange$ = fromEvent(window, 'fullscreenchange').pipe(debounceTime(100));

        resizeScroll$.subscribe(() => {
            updateBarsPositions();
        });

        resizeWindow$.subscribe(() => {
            recalculateBars();
            updateBarsPositions();
        });

        fullscreenChange$.subscribe(() => {
            recalculateBars();
            updateBarsPositions();
        });

        el.__resizeObserver__ = recalculateBars;
    },

    unbind(el) {
        window.removeEventListener('resize', el.__resizeObserver__);
        delete el.__resizeObserver__;
    }
});