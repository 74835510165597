import { storeAsync } from '@/app-module';

const getApplicationStatusModule = () => import('@/modules/application-status/application-status-store');
const QRCodeStatus = () => import('@/modules/application-status/pages/QRCodeStatus.vue');

const importApplicationStatusStore = () =>
    getApplicationStatusModule().then(async (storeModule) => {
        if (!storeAsync().hasModule('application-status')) await storeAsync().registerModule('application-status', storeModule.default);
        else return;
    });

export default [
    {
        path: '/qr/:id',
        name: 'ApplicationStatus',
        component: QRCodeStatus,
        beforeEnter: (to, from, next) => {
            importApplicationStatusStore().then(() => {
                next();
            });
        },
    }
];
