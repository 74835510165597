<template>
  <div>
    <b-icon-chevron-double-right v-b-tooltip.hover :title="$t('APPLICATION_TYPE.FIELDS.IS_INBOUND_CATEGORY')" v-if="dataObj.item.application_type_is_inbound_category" aria-hidden="true" class="h3 mr-1" variant="primary"></b-icon-chevron-double-right>
    <b-icon-chevron-double-left v-b-tooltip.hover :title="$t('APPLICATION_TYPE.FIELDS.IS_OUTBOUND_CATEGORY')" v-if="dataObj.item.application_type_is_outbound_category" aria-hidden="true" class="h3 mr-1" variant="success"></b-icon-chevron-double-left>
    <b-icon-arrow-counterclockwise v-b-tooltip.hover :title="$t('APPLICATION_TYPE.FIELDS.IS_INTERNAL_CATEGORY')" v-if="dataObj.item.application_type_is_internal_category" aria-hidden="true" class="h3 mr-1" variant="warning"></b-icon-arrow-counterclockwise>
  </div>
</template>

<script>
import {  BIconChevronDoubleRight, BIconChevronDoubleLeft, BIconArrowCounterclockwise, VBTooltip  } from 'bootstrap-vue';
export default {
  name: "CategoryUsageTypeCustomField",
  components: {
    BIconChevronDoubleRight,
    BIconChevronDoubleLeft,
    BIconArrowCounterclockwise
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    dataObj: {
      type: Object,
    },
  },
};
</script>
