export default function email() {
    return {
        validate: function(input) {
            const { value } = input;

            const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const valid = re.test(value);

            return {
                valid,
            }
        }
    }
}