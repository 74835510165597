<template>
  <div class="font-weight-bold" v-if="!editMode">
    {{
      dataObj.item.response_due_in_days
        ? `${dataObj.item.response_due_in_days} ${i18n.tc(
            "GENERAL.DAYS",
            Number(dataObj.item.response_due_in_days)
          )}`
        : ""
    }}
    <b-icon-pencil
      v-if="canEdit"
      font-scale="1.125"
      variant="primary"
      class="ml-2 cursor-pointer"
      @click="switchToEditMode()"
    ></b-icon-pencil>
  </div>
  <div v-else>
    <b-input-group class="d-flex align-items-center">
      <b-form-input
        type="number"
        :min="0"
        :max="9999"
        v-model="snapshotValue"
        :placeholder="$t('APPLICATION.PLACEHOLDERS.RESPONSE_DUE_IN')"
        class="form-control-sm"
        :state="inputState"
        @focus="inputState = null"
      >
      </b-form-input>
      <b-form-invalid-feedback> Introdu un numar </b-form-invalid-feedback>
      <b-input-group-append v-if="inputState == null" @click="onEdit">
        <b-icon-check
          font-scale="2"
          class="ml-2 cursor-pointer"
          variant="success"
        ></b-icon-check>
      </b-input-group-append>
      <b-input-group-append v-if="inputState == null" @click="editMode = false">
        <b-icon-x font-scale="2" class="ml-2 cursor-pointer" variant="danger"></b-icon-x>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import i18n from "@/shared/plugins/vue-i18n";
import { mapGetters, mapActions } from "vuex";
import {
  BIconPencil,
  BIconCheck,
  BIconX,
  BInputGroupAppend,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";

export default {
  name: "ResponseDaysCustomField",
  props: {
    dataObj: {
      type: Object,
    },
  },
  components: {
    BIconPencil,
    BInputGroupAppend,
    BIconCheck,
    BIconX,
    BFormInvalidFeedback,
  },
  data() {
    return {
      i18n,
      editMode: false,
      snapshotValue: null,
      inputState: null,
    };
  },
  created() {
    this.snapshotValue = this.dataObj.item.response_due_in_days;
  },
  methods: {
    ...mapActions({
      updateResponseDueInDays: "applications/form/doUpdateResponseDueInDays",
    }),
    switchToEditMode() {
      this.snapshotValue = this.dataObj.item.response_due_in_days;
      this.editMode = true;
    },
    async onEdit() {
      this.validateInput();
      if (!this.inputState) {
        return;
      }
      this.editMode = false;
      await this.updateResponseDueInDays({
        applicationId: this.dataObj.item.id,
        payload: {
          application_type_remaining_period: parseInt(this.snapshotValue),
          application_date: this.dataObj.item.application_date
        },
      });
      this.dataObj.item.response_due_in_days = this.snapshotValue;
    },
    validateInput() {
      if (!this.snapshotValue || isNaN(this.snapshotValue)) {
        this.inputState = false;
      } else {
        this.inputState = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    canEdit() {
      return new ApplicationsPermissions(this.currentUser).responseDueDaysEdit;
    },
  },
};
</script>
