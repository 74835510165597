import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import { setupComponentsFiltersDirectivesAndMixins, storeAsync, routerAsync } from './app-module';
import App from './App.vue';

import ApiService from './shared/services/api.service';
// import MockService from './core/mock/mock.service';
import '@/shared/utils/notify';
import '@/shared/utils/alert';
import { renderInApp } from './core/helpers/globalMethods';

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';

// Vue 3rd party plugins
import i18n from '@/shared/plugins/vue-i18n';
import '@/shared/plugins/portal-vue';
import '@/shared/plugins/bootstrap-vue';
import '@/shared/plugins/perfect-scrollbar';
import '@/shared/plugins/inline-svg';
import '@/shared/plugins/metronic';
import '@/shared/plugins/formvalidation';
import '@/shared/plugins/columns-resizable';

import { registerLicense } from '@syncfusion/ej2-base';
// TO DO: Add license key
registerLicense('license key');


// API service init
ApiService.init();

// TODO: Remove this to disable mock API
// MockService.init();

Vue.use(Router);
Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.use(Vuex);
setupComponentsFiltersDirectivesAndMixins();

Vue.prototype.$renderInApp = renderInApp();

new Vue({
    store: storeAsync(),
    router: routerAsync(),
    i18n,
    data: {
        renderInApp: renderInApp(),
    },
    render: (h) => h(App),
}).$mount('#app');
